// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { createForm } from 'final-form';
import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';
import axios from 'axios';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const createFormData = body => {
  const formData = new FormData();
  formData.append('file', body); // Assuming `body` is the file object
  return formData;
};

const post = (path, body, apiUrl = '', multipart) => {
  const url = `${apiUrl === '' ? apiBaseUrl() : apiUrl}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    body: multipart ? createFormData(body.file) : serialize(body),
  };

  if (!multipart) {
    options.headers = {
      'Content-Type': 'application/transit+json',
    };
  }

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

const get = path => {
  console.log(process.env.REACT_APP_API_BASE_URL);
  const url = `${process.env.REACT_APP_API_BASE_URL}${path}`;
  const options = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
  };

  return window.fetch(url, options).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }

    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }

    return res.text();
  });
};

const formBase64EncodedString = (secretKey, password) => {
  const stringToEncode = `${secretKey}:${password}`;
  return Buffer.from(stringToEncode).toString('base64');
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

export const getPaymentMethods = body => {
  return get('/api/v1/payments/methods');
};

export const getPaymentIntent = id => {
  return get(`/api/v1/payments/payment-intent/${id}`);
};

export const createPaymentMethodApi = body => {
  return post('/api/v1/payments/payment-method', body, process.env.REACT_APP_API_BASE_URL);
};

export const createPaymentIntentApi = body => {
  return post('/api/v1/payments/payment-intent', body, process.env.REACT_APP_API_BASE_URL);
};

export const attachPaymentIntentApi = (id, body) => {
  return post(
    `/api/v1/payments/payment-intent/${id}/attach`,
    body,
    process.env.REACT_APP_API_BASE_URL
  );
};

export const uploadIdentification = body => {
  return post(`/api/v1/files/upload`, body, process.env.REACT_APP_API_BASE_URL, true);
};

export const capturePaymentApi = async (id, body) => {
  const secretBase64 = btoa(process.env.REACT_APP_PAYMONGO_SECRET);

  const response = await axios.post(
    `${process.env.REACT_APP_PAYMONGO_API_URL}/v1/payment_intents/${id}/capture`,
    body,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Basic ${secretBase64}`,
      },
    }
  );

  return response;
};

export const postInvoice = async body => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/payments/invoices`,
    body
  );

  return response;
};

export const getInvoice = async id => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/payments/invoices/${id}`
  );

  return response;
};

export const patchTxMetadata = async body => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/integration/transaction/metadata/${body.id}`,
    body
  );

  return response;
};

export const getPayoutChannels = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/api/v1/payments/payout-channels?channel_category=BANK,EWALLET`
  );

  return response;
};
