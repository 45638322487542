import pick from 'lodash/pick';
import {
  createStripeAccount,
  updateStripeAccount,
  fetchStripeAccount,
} from '../../ducks/stripeConnectAccount.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { storableError } from '../../util/errors';
import { getPayoutChannels } from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/StripePayoutPage/SET_INITIAL_VALUES';
export const SAVE_PAYOUT_DETAILS_REQUEST = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_REQUEST';
export const SAVE_PAYOUT_DETAILS_SUCCESS = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_SUCCESS';
export const SAVE_PAYOUT_DETAILS_ERROR = 'app/StripePayoutPage/SAVE_PAYOUT_DETAILS_ERROR';

export const FETCH_PAYOUT_CHANNELS_REQUEST = 'app/StripePayoutPage/FETCH_PAYOUT_CHANNELS_REQUEST';
export const FETCH_PAYOUT_CHANNELS_ERROR = 'app/StripePayoutPage/FETCH_PAYOUT_CHANNELS_ERROR';
export const FETCH_PAYOUT_CHANNELS_SUCCESS = 'app/StripePayoutPage/FETCH_PAYOUT_CHANNELS_SUCCESS';

// ================ Reducer ================ //

const initialState = {
  payoutDetailsSaveInProgress: false,
  payoutDetailsSaved: false,
  fromReturnURL: false,
  payoutChannels: null,
  payoutChannelsInProgress: false,
  payoutChannelsError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SAVE_PAYOUT_DETAILS_REQUEST:
      return { ...state, payoutDetailsSaveInProgress: true };
    case SAVE_PAYOUT_DETAILS_ERROR:
      return { ...state, payoutDetailsSaveInProgress: false };
    case SAVE_PAYOUT_DETAILS_SUCCESS:
      return { ...state, payoutDetailsSaveInProgress: false, payoutDetailsSaved: true };
    case FETCH_PAYOUT_CHANNELS_REQUEST:
      return {
        ...state,
        payoutChannelsInProgress: true,
        payoutChannelsError: null,
      };
    case FETCH_PAYOUT_CHANNELS_ERROR:
      return {
        ...state,
        payoutChannelsInProgress: false,
        payoutChannelsError: payload,
      };
    case FETCH_PAYOUT_CHANNELS_SUCCESS:
      return {
        ...state,
        payoutChannelsInProgress: false,
        payoutChannels: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const savePayoutDetailsRequest = () => ({
  type: SAVE_PAYOUT_DETAILS_REQUEST,
});
export const savePayoutDetailsError = () => ({
  type: SAVE_PAYOUT_DETAILS_ERROR,
});
export const savePayoutDetailsSuccess = () => ({
  type: SAVE_PAYOUT_DETAILS_SUCCESS,
});

export const fetchPayoutChannelsRequest = () => ({
  type: FETCH_PAYOUT_CHANNELS_REQUEST,
});

export const fetchPayoutChannelsError = error => ({
  type: FETCH_PAYOUT_CHANNELS_ERROR,
  payload: error,
});

export const fetchPayoutChannelsSuccess = payload => ({
  type: FETCH_PAYOUT_CHANNELS_SUCCESS,
  payload: payload,
});

// ================ Thunks ================ //

export const savePayoutDetails = values => (dispatch, getState, sdk) => {
  dispatch(savePayoutDetailsRequest());

  return sdk.currentUser
    .updateProfile({
      privateData: {
        ...values,
      },
    })
    .then(res => {
      dispatch(savePayoutDetailsSuccess(res.data));

      return res.data;
    })
    .catch(e => {
      log.error(storableError(e), 'update-payout-details-failed');
      dispatch(savePayoutDetailsError(storableError(e)));
    });
};

export const fetchPayoutChannels = () => dispatch => {
  dispatch(fetchPayoutChannelsRequest());

  return getPayoutChannels()
    .then(response => {
      dispatch(fetchPayoutChannelsSuccess(response.data));
      return response.data;
    })
    .catch(error => {
      dispatch(fetchPayoutChannelsError(error));
      return error;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialValues());

  dispatch(fetchPayoutChannels())

  return dispatch(fetchCurrentUser()).then(response => {
    return response;
  });
};
