import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { Button, FieldSelect, FieldTextInput, Form } from '../../components';
import css from './PayoutDetailsPage.module.css';
import { composeValidators, required } from '../../util/validators';
import { FormattedMessage } from 'react-intl';

const PayoutDetailsForm = ({ onSubmit, ...rest }) => {
  const handleSubmit = values => {
    onSubmit(values);
  };

  const handlePaymentMethodChange = e => {
    setPaymentMethod(e || '');
  };

  const payoutDetailsForm = formRenderProps => {
    const {
      rootClassName,
      className,
      intl,
      handleSubmit,
      formId,
      autoFocus,
      inProgress,
      ready,
      submitButtonText,
      currentUser,
      payoutChannels,
    } = formRenderProps;

    const classes = classNames(rootClassName || css.root, className);

    const submitInProgress = inProgress;

    const accountHolderNamerequiredMessage = intl.formatMessage({
      id: 'PayoutDetailsForm.accountHolderNameRequired',
    });

    const accountNumberRequiredMessage = intl.formatMessage({
      id: 'PayoutDetailsForm.accountNumberRequiredMessage',
    });

    const privateData = currentUser.attributes.profile.privateData;

    const { accountHolderName, accountNumber, channelName } = privateData;

    return (
      <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="PayoutDetailsPage">
        <div className={css.inputWrapper}>
          <FieldSelect
            id={`channelName`}
            name="channelName"
            label={intl.formatMessage({
              id: 'PayoutDetailsForm.channelNameLabel',
            })}
            value={channelName}
            onChange={handlePaymentMethodChange}
            validate={required(intl.formatMessage({ id: 'PayoutDetailsForm.channelNameRequired' }))}
          >
            <option disabled value="">
              {intl.formatMessage({ id: 'PayoutDetailsForm.channelNameOption' })}
            </option>
            {payoutChannels.map((channel, index) => (
              <option key={index} value={channel.channel_code}>
                {channel.channel_name}
              </option>
            ))}
          </FieldSelect>
        </div>

        <div className={css.inputWrapper}>
          <FieldTextInput
            id={`${formId}AccountHolderName`}
            name="accountHolderName"
            type="text"
            value={accountHolderName}
            label={intl.formatMessage({ id: 'PayoutDetailsForm.accountHolderNameLabel' })}
            placeholder={intl.formatMessage({
              id: 'PayoutDetailsForm.accountHolderNamePlaceholder',
            })}
            validate={composeValidators(required(accountHolderNamerequiredMessage))}
            autoFocus={autoFocus}
          />
        </div>

        <div className={css.inputWrapper}>
          <FieldTextInput
            id={`${formId}AccountNumber`}
            name="accountNumber"
            type="text"
            value={accountNumber}
            label={intl.formatMessage({ id: 'PayoutDetailsForm.accountNumberLabel' })}
            placeholder={intl.formatMessage({ id: 'PayoutDetailsForm.accountNumberPlaceholder' })}
            validate={composeValidators(required(accountNumberRequiredMessage))}
            autoFocus={autoFocus}
          />
        </div>

        <Button type="submit" inProgress={submitInProgress} ready={ready}>
          {submitButtonText || <FormattedMessage id="PayoutDetailsForm.submitButtonText" />}
        </Button>
      </Form>
    );
  };

  const privateData = rest.currentUser.attributes.profile.privateData;

  const { accountHolderName, accountNumber, channelName } = privateData;

  const initialValues = {
    accountHolderName,
    accountNumber,
    channelName,
  };

  return (
    <FinalForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      {...rest}
      render={payoutDetailsForm}
    />
  );
};

export default PayoutDetailsForm;
