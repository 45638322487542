/**
 * Slice a local number that is in the form
 * of 555 01234567
 */
const sliceLocal = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 10)}`;
};

/**
 * Slice an international phone number i.e.
 * the part that is followed after a '+' or '00'.
 */
const sliceInternational = numbers => {
  if (numbers.length <= 3) {
    return numbers;
  }
  if (numbers.length <= 5) {
    return `${numbers.slice(0, 3)} ${numbers.slice(3)}`;
  }
  return `${numbers.slice(0, 3)} ${numbers.slice(3, 5)} ${numbers.slice(5, 12)}`;
};

export const format = value => {
  if (!value) {
    return '';
  }

  const numbers = value.replace(/[^\d]/g, '');

  if (numbers.length === 12 && numbers.startsWith('639')) {
    return `+63 ${numbers.substring(2, 5)} ${numbers.substring(5, 8)} ${numbers.substring(8)}`;
  }

  if (numbers.length === 11 && numbers.startsWith('09')) {
    return `0${numbers.substring(1, 4)} ${numbers.substring(4, 7)} ${numbers.substring(7)}`;
  }

  return value;
};


/**
 * Parser that strips whitespaces away from a phone number
 * string so that the plain number can be stored.
 */
export const parse = value => (value ? value.replace(/\s/g, '') : '');
