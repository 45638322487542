import pick from 'lodash/pick';
import {
  initiatePrivileged,
  patchTxMetadata,
  postInvoice,
  transitionPrivileged,
} from '../../util/api';
import { denormalisedResponseEntities, filterAllowedItems } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import {
  transactionLineItems,
  getPaymentMethods,
  getPaymentIntent,
  createPaymentMethodApi,
  createPaymentIntentApi,
  attachPaymentIntentApi,
} from '../../util/api';

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/CheckoutPage/FETCH_LINE_ITEMS_REQUEST';

export const FETCH_LINE_ITEMS_SUCCESS = 'app/CheckoutPage/FETCH_LINE_ITEMS_SUCCESS';

export const GET_PAYMENT_METHODS_REQUEST = 'app/CheckoutPage/GET_PAYMENT_METHODS_REQUEST';
export const GET_PAYMENT_METHODS_SUCCESS = 'app/CheckoutPage/GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'app/CheckoutPage/GET_PAYMENT_METHODS_ERROR';

export const FETCH_PAYMENT_LINK_REQUEST = 'app/CheckoutPage/GET_PAYMENT_LINK_REQUEST';

export const FETCH_PAYMENT_INTENT_REQUEST = 'app/CheckoutPage/FETCH_PAYMENT_INTENT_REQUEST';
export const FETCH_PAYMENT_INTENT_SUCCESS = 'app/CheckoutPage/FETCH_PAYMENT_INTENT_SUCCESS';
export const FETCH_PAYMENT_INTENT_ERROR = 'app/CheckoutPage/FETCH_PAYMENT_INTENT_ERROR';

export const CREATE_PAYMENT_METHOD_REQUEST = 'app/CheckoutPage/CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'app/CheckoutPage/CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_ERROR = 'app/CheckoutPage/CREATE_PAYMENT_METHOD_ERROR';

export const CREATE_PAYMENT_INTENT_REQUEST = 'app/CheckoutPage/CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'app/CheckoutPage/CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_ERROR = 'app/CheckoutPage/CREATE_PAYMENT_INTENT_ERROR';

export const CREATE_INVOICE_REQUEST = 'app/CheckoutPage/CREATE_INVOICE_REQUEST';
export const CREATE_INVOICE_SUCCESS = 'app/CheckoutPage/CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'app/CheckoutPage/CREATE_INVOICE_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  getPaymentMethodsInProgress: false,
  paymentMethods: [],
  getPaymentMethodsError: null,
  fetchPaymentIntentInProgress: false,
  fetchPaymentIntentError: null,
  paymentIntent: null,
  createPaymentMethodInProgress: false,
  createPaymentMethodError: null,
  createPaymentMethodSuccess: null,
  paymentMethod: null,
  createPaymentIntentInProgress: false,
  createPaymentIntentError: null,
  createPaymentMethodSuccess: null,
  invoice: null,
  createInvoiceInProgress: false,
  createInvoiceSuccess: false,
  createInvoiceError: false,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case FETCH_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        fetchLineItemsInProgress: false,
        speculatedTransaction: {
          ...state.speculatedTransaction,
          attributes: {
            ...state.speculatedTransaction.attributes,
            lineItems: payload,
          },
        },
      };
    case GET_PAYMENT_METHODS_REQUEST:
      return {
        ...state,
        getPaymentMethodsInProgress: true,
        getPaymentMethodsError: null,
      };
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        getPaymentMethodsInProgress: false,
        paymentMethods: payload,
      };
    case GET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        getPaymentMethodsInProgress: false,
        getPaymentMethodsError: payload,
      };
    case FETCH_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        fetchPaymentIntentInProgress: true,
        fetchPaymentIntentError: null,
      };
    case FETCH_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        fetchPaymentIntentInProgress: false,
        paymentIntent: payload,
      };
    case FETCH_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        fetchPaymentIntentInProgress: false,
        fetchPaymentIntentError: payload,
      };
    case CREATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        createPaymentMethodInProgress: true,
        createPaymentMethodError: null,
      };
    case CREATE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        createPaymentMethodInProgress: false,
        paymentMethod: payload,
      };
    case CREATE_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        createPaymentMethodInProgress: false,
        createPaymentMethodError: payload,
      };
    case CREATE_PAYMENT_INTENT_REQUEST:
      return {
        ...state,
        createPaymentIntentInProgress: true,
        createPaymentIntentError: null,
      };
    case CREATE_PAYMENT_INTENT_SUCCESS:
      return {
        ...state,
        createPaymentIntentInProgress: false,
        paymentIntent: payload,
      };
    case CREATE_PAYMENT_INTENT_ERROR:
      return {
        ...state,
        createPaymentIntentInProgress: false,
        createPaymentIntentError: payload,
      };
    case CREATE_INVOICE_REQUEST:
      return {
        ...state,
        createInvoiceInProgress: true,
        createInvoiceError: null,
      };
    case CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        createInvoiceInProgress: false,
        createInvoiceSuccess: true,
        invoice: payload,
      };
    case CREATE_INVOICE_ERROR:
      return {
        ...state,
        createInvoiceInProgress: false,
        createInvoiceError: payload,
      };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });

export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});

const getPaymentMethodsRequest = () => ({ type: GET_PAYMENT_METHODS_REQUEST });

const getPaymentMethodsSuccess = paymentMethods => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload: paymentMethods,
});

const getPaymentMethodsError = error => ({
  type: GET_PAYMENT_METHODS_ERROR,
  payload: error,
});

const fetchPaymentLinkrequest = () => ({
  type: FETCH_PAYMENT_LINK_REQUEST,
});

const fetchPaymentIntentRequest = () => ({
  type: FETCH_PAYMENT_INTENT_REQUEST,
});

const fetchPaymentIntentSuccess = paymentIntent => ({
  type: FETCH_PAYMENT_INTENT_SUCCESS,
  payload: paymentIntent,
});

const fetchPaymentIntentError = error => ({
  type: FETCH_PAYMENT_INTENT_ERROR,
  payload: error,
});

const createPaymentMethodRequest = () => ({
  type: CREATE_PAYMENT_METHOD_REQUEST,
});

const createPaymentMethodSuccess = paymentMethod => ({
  type: CREATE_PAYMENT_METHOD_SUCCESS,
  payload: paymentMethod,
});

const createPaymentMethodError = error => ({
  type: CREATE_PAYMENT_METHOD_ERROR,
  payload: error,
});

const createPaymentIntentRequest = () => ({
  type: CREATE_PAYMENT_INTENT_REQUEST,
});

const createPaymentIntentSuccess = paymentIntent => ({
  type: CREATE_PAYMENT_INTENT_SUCCESS,
  payload: paymentIntent,
});

const createPaymentIntentError = error => ({
  type: CREATE_PAYMENT_INTENT_ERROR,
  payload: error,
});

const createInvoiceRequest = () => ({
  type: CREATE_INVOICE_REQUEST,
});

const createInvoiceSuccess = payload => ({
  type: CREATE_INVOICE_SUCCESS,
  payload: payload,
});

const createInvoiceError = error => ({
  type: CREATE_INVOICE_ERROR,
  payload: error,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const {
    deliveryMethod,
    quantity,
    bookingDates,
    hasDeliveryFee,
    hasPickUpFee,
    distance,
    ...otherOrderParams
  } = orderParams;

  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = {
    ...bookingDates,
    ...hasDeliveryFee,
    ...hasPickUpFee,
    ...distance,
  };

  // Parameters only for client app's server
  const orderData =
    deliveryMethod || hasDeliveryFee || hasPickUpFee || distance
      ? { deliveryMethod, hasDeliveryFee, hasPickUpFee, distance }
      : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const confirmPayment = (transactionId, transitionName, transitionParams = {}) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const {
    deliveryMethod,
    quantity,
    bookingDates,
    hasDeliveryFee,
    hasPickUpFee,
    ...otherOrderParams
  } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData =
    deliveryMethod || hasDeliveryFee || hasPickUpFee
      ? { deliveryMethod, hasDeliveryFee, hasPickUpFee }
      : {};

  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
        id: transactionId,
        transition: transitionName,
        params: transitionParams,
      }
    : {
        processAlias,
        transition: transitionName,
        params: transitionParams,
      };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
      .then(handleSuccess)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const fetchPaymentMethods = () => (dispatch, getState, sdk) => {
  dispatch(getPaymentMethodsRequest());

  getPaymentMethods()
    .then(response => {
      const paymentMethods = filterAllowedItems(response.data); // Adjust this based on the actual response structure
      dispatch(getPaymentMethodsSuccess(paymentMethods));
    })
    .catch(error => {
      dispatch(getPaymentMethodsError(error));
    });
};

export const fetchPaymentIntent = paymentIntentId => dispatch => {
  dispatch(fetchPaymentIntentRequest());

  const handleSuccess = res => {
    if (res.error) {
      dispatch(fetchPaymentIntentError(res.error));
      return null;
    }

    const paymentIntent = res.data;

    dispatch(fetchPaymentIntentSuccess(paymentIntent));

    return paymentIntent;
  };

  const handleError = e => {
    dispatch(fetchPaymentIntentError(e));

    return null;
  };

  return getPaymentIntent(paymentIntentId)
    .then(handleSuccess)
    .catch(handleError);
};

export const createPaymentIntent = params => dispatch => {
  const { amount, paymentMethods } = params;

  dispatch(createPaymentIntentRequest());

  const body = {
    amount: amount,
    payment_method_allowed: paymentMethods,
  };

  const handleSuccess = res => {
    dispatch(createPaymentIntentSuccess(res.data));

    return res.data;
  };

  const handleError = error => {
    dispatch(createPaymentIntentError(error));

    return error;
  };

  return createPaymentIntentApi(body)
    .then(handleSuccess)
    .catch(handleError);
};

export const createPaymentMethod = params => dispatch => {
  dispatch(createPaymentMethodRequest());

  const body = params => {
    return {
      type: params.type,
      card_number: params.cardNumber,
      exp_year: params.expYear,
      exp_month: params.expMonth,
      cvc: params.cvv,
    };
  };

  const handleSuccess = res => {
    dispatch(createPaymentMethodSuccess(res.data));

    return res.data;
  };

  const handleError = e => {
    dispatch(createPaymentMethodError(e));

    return null;
  };

  return createPaymentMethodApi(body(params))
    .then(handleSuccess)
    .catch(handleError);
};

export const attachPaymentIntent = params => dispatch => {
  const { paymentIntentId, paymentMethod, returnUrl } = params;

  const handleSuccess = res => {
    return res.data;
  };

  const handleError = e => {
    return null;
  };

  return attachPaymentIntentApi(paymentIntentId, {
    return_url: returnUrl,
    payment_method: paymentMethod,
  })
    .then(handleSuccess)
    .catch(handleError);
};

export const createInvoice = params => (dispatch, getState) => {
  const { transactionId, amount, firstName, lastName, email, mobileNumber } = params;

  const body = {
    transaction_id: transactionId,
    amount: amount,
    first_name: firstName,
    last_name: lastName,
    email: email,
    mobile_number: mobileNumber,
    redirect_url: `${process.env.REACT_APP_MARKETPLACE_ROOT_URL}/order/${transactionId}`,
  };

  const handleSuccess = async res => {
    const invoice = res?.data?.data;

    // patch transaction metadata
    const body = {
      id: transactionId,
      metadata: {
        invoice,
      },
    };

    await patchTxMetadata(body);

    dispatch(createInvoiceSuccess(res.data.data));
    return res.data.data;
  };

  const handleError = e => {
    dispatch(createInvoiceError(e));

    return e.message;
  };

  return postInvoice(body)
    .then(handleSuccess)
    .catch(handleError);
};
