import React from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { FieldSelect, H3, LayoutSideNavigation, Page, UserNav } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { ensureCurrentUser } from '../../util/data';
import PayoutDetailsForm from './PayoutDetailsForm';
import { fetchPayoutChannels, savePayoutDetails } from './PayoutDetailsPage.duck';

import css from './PayoutDetailsPage.module.css';
import { Form } from 'react-final-form';

export const PayoutDetailsPageComponent = props => {
  const {
    scrollingDisabled,
    intl,
    currentUser,
    payoutDetailsSaveInProgress,
    onPayoutDetailsSubmit,
    payoutDetailsSaved,
    payoutChannels,
    payoutChannelsError,
    payoutChannelsInProgress,
  } = props;

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const pageLoaded = !!ensuredCurrentUser.id && !payoutChannelsInProgress && !!payoutChannels;

  const title = intl.formatMessage({ id: 'StripePayoutPage.title' });

  const handlePayoutDetailsFormSubmit = e => {
    onPayoutDetailsSubmit(e);
  };

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation
        topbar={
          <>
            <TopbarContainer
              currentPage="PayoutDetailsPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.moboileTopbar}
            />
            <UserNav currentPage="PayoutDetailsPage" />
          </>
        }
        sideNav={null}
        useAccountSettingsNav
        currentPage="PayoutDetailsPage"
        footer={<FooterContainer />}
      >
        <div className={css.content}>
          <H3 as="h1" className={css.heading}>
            <FormattedMessage id="PayoutDetailsPage.heading" />
          </H3>
          {!pageLoaded ? (
            <FormattedMessage id="PayoutDetailsPage.loadingData" />
          ) : payoutChannelsError ? (
            <div className={css.error}>
              <FormattedMessage id="PayoutDetailsPage.payoutChannelsError" />
            </div>
          ) : (
            <PayoutDetailsForm
              intl={intl}
              onSubmit={handlePayoutDetailsFormSubmit}
              inProgress={payoutDetailsSaveInProgress}
              ready={payoutDetailsSaved}
              currentUser={ensuredCurrentUser}
              payoutChannels={payoutChannels}
            />
          )}
        </div>
      </LayoutSideNavigation>
    </Page>
  );
};

PayoutDetailsPageComponent.defaultProps = {
  currentUser: null,
};

PayoutDetailsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    payoutChannels,
    payoutChannelsError,
    payoutChannelsInProgress,
  } = state.PayoutDetailsPage;

  return {
    currentUser,
    payoutDetailsSaveInProgress,
    payoutDetailsSaved,
    scrollingDisabled: isScrollingDisabled(state),
    payoutChannels,
    payoutChannelsError,
    payoutChannelsInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onPayoutDetailsSubmit: values => dispatch(savePayoutDetails(values)),
  onFetchPayoutChannels: () => dispatch(fetchPayoutChannels()),
});

const PayoutDetailsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PayoutDetailsPageComponent);

export default PayoutDetailsPage;
